import { useRef, useState, useEffect, useContext } from "react";

import { UserContext } from "../../../../../context/user-context";

import { NavLink, useHistory } from "react-router-dom";

import { useMsal } from "@azure/msal-react";

import {
  Avatar,
  Box,
  Button,
  Divider,
  Hidden,
  lighten,
  List,
  ListItem,
  ListItemText,
  Popover,
  Typography,
  styled,
} from "@mui/material";

import LockOpenTwoToneIcon from "@mui/icons-material/LockOpenTwoTone";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import MenuBookOutlinedIcon from "@mui/icons-material/MenuBookOutlined";
import GroupAddOutlinedIcon from "@mui/icons-material/GroupAddOutlined";

const UserBoxButton = styled(Button)(
  ({ theme }) => `
        padding-left: ${theme.spacing(1)};
        padding-right: ${theme.spacing(1)};
`
);

const MenuUserBox = styled(Box)(
  ({ theme }) => `
        background: #003366f2;
        padding: ${theme.spacing(2)};
`
);

const UserBoxText = styled(Box)(
  ({ theme }) => `
        text-align: left;
        padding-left: ${theme.spacing(1)};
`
);

const UserBoxLabel = styled(Typography)(
  ({ theme }) => `
        color: white;
        display: block;
`
);

const UserBoxDescription = styled(Typography)(
  ({ theme }) => `
        color: white
`
);

function HeaderUserbox({ onLoginChange }) {
  const { instance } = useMsal();
  const {
    user,
    socket,
    dataChangedArr,
    handleDialogClickOpen,
    setNavigationHandler,
  } = useContext(UserContext);
  const history = useHistory();

  const handleLogout = () => {
    !userInfo?.user_group?.includes("Admin") &&
      socket.emit(
        "message",
        JSON.stringify({
          operation: "clear",
          data: user?.display_name,
        })
      );
    instance.logoutRedirect()({
      postLogoutRedirectUri: "/",
      mainWindowRedirectUri: "/",
    });
  };

  const userInfo = {
    name: user?.display_name || "",
    avatar: "/static/images/avatars/1.jpg",
    jobtitle: user?.user_group[0] || "",
  };

  const ref = useRef(null);
  const [isOpen, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const changeToNonModelRelatedPageHandler = (e, path) => {
    e.preventDefault();
    setOpen(false);
    if (dataChangedArr.length > 0) {
      setNavigationHandler(path);
      handleDialogClickOpen();
    } else {
      history.push(path);
    }
  };

  useEffect(() => {
    socket.on("connection", () => {
      // console.log("connected");
    });

    socket.on("message", (data) => {
      if (JSON.parse(data) && JSON.parse(data)?.logout === userInfo?.name) {
        handleLogout();
      }
    });

    return () => {
      socket.off("message");
    };
  }, []);

  return (
    <div style={{ display: "flex", justifyContent: "flex-end" }}>
      <UserBoxButton
        color="secondary"
        ref={ref}
        onClick={handleOpen}
        style={{ width: "100%" }}
      >
        <Hidden smDown>
          {/*<ExpandMoreTwoToneIcon sx={{ ml: -1 }} color="info" />*/}
        </Hidden>
        <Avatar
          variant="rounded"
          src={userInfo.avatar}
          alt={userInfo.name}
          sx={{ background: "#FF9100" }}
        />
        <Hidden mdDown></Hidden>
      </UserBoxButton>
      {
        <Popover
          anchorEl={ref.current}
          onClose={handleClose}
          open={isOpen}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <MenuUserBox sx={{ minWidth: 210 }} display="flex">
            <Avatar
              variant="rounded"
              alt={userInfo.name}
              src={userInfo.avatar}
              sx={{ background: "#FF9100" }}
            />
            <UserBoxText>
              <UserBoxLabel variant="body1">{userInfo.name}</UserBoxLabel>
              <UserBoxDescription variant="body2">
                {userInfo.jobtitle}
              </UserBoxDescription>
            </UserBoxText>
          </MenuUserBox>
          <Divider sx={{ mb: 0 }} />
          <List sx={{ p: 1 }} component="nav">
            <ListItem
              button
              to="/task"
              component={NavLink}
              onClick={(e) => changeToNonModelRelatedPageHandler(e, "/task")}
            >
              <AssignmentOutlinedIcon
                fontSize="small"
                sx={{ color: "#333333" }}
              />
              <ListItemText primary="My Task" />
            </ListItem>
            <ListItem
              button
              to="/Download File/BBD-MGS User Manual.pdf"
              target="_blank"
              rel="noopener noreferrer"
              download
              component={NavLink}
              onClick={handleClose}
            >
              <MenuBookOutlinedIcon
                fontSize="small"
                sx={{ color: "#CB3694" }}
              />
              <ListItemText primary="Help File" />
            </ListItem>
            <ListItem
              button
              to="/userlist"
              component={NavLink}
              onClick={(e) =>
                changeToNonModelRelatedPageHandler(e, "/userlist")
              }
            >
              <GroupAddOutlinedIcon
                fontSize="small"
                sx={{ color: "#FFCB05" }}
              />
              <ListItemText primary="User List" />
            </ListItem>
          </List>
          <Divider />
          <Box sx={{ m: 1 }}>
            <Button color="primary" fullWidth onClick={() => handleLogout()}>
              <LockOpenTwoToneIcon sx={{ mr: 1 }} />
              Sign out
            </Button>
          </Box>
        </Popover>
      }
    </div>
  );
}

export default HeaderUserbox;
