import React, { useReducer, useEffect, useRef, useState } from "react";
import dayjs from "dayjs";
import MetricTable from "./MetricTable2";
import OpmMetric from "./OpmMetric2";
import MacTable from "./MacTable";
import uniqBy from "lodash/uniqBy";
import uniq from "lodash/uniq";
import ReactQuill from "react-quill";
import Quill from "quill";
import "react-quill/dist/quill.snow.css";

import { validate } from "../../Utils/Validators";
import {
  TextField,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  IconButton,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import UpdateOutlinedIcon from "@mui/icons-material/UpdateOutlined";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import UpdateDisabledOutlinedIcon from "@mui/icons-material/UpdateDisabledOutlined";
import Table from "react-bootstrap/Table";
import { Select } from "antd";
import "./InputNew.css";

const { Option } = Select;

const FONT_SIZE = [];
const Size = Quill.import("attributors/style/size");
Size.whitelist = FONT_SIZE;
Quill.register(Size, true);

const formats = [
  "align",
  "background",
  "blockquote",
  "bold",
  "bullet",
  "code",
  "code-block",
  "color",
  "direction",
  "font",
  "formula",
  "header",
  "image",
  "indent",
  "italic",
  "link",
  "list",
  "script",
  "size",
  "strike",
  "table",
  "underline",
  "video",
];

const modules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    [{ font: [] }],
    [{ size: Size.whitelist }], // Custom numeric font sizes
    ["bold", "italic", "underline", "strike", "blockquote"],
    [{ list: "ordered" }, { list: "bullet" }],
    [{ color: [] }, { background: [] }],
    ["link", "image", "video"],
    ["clean"], // Remove formatting button
  ],
};

const timeConverter = (time) => {
  if (time.includes("T") && time?.split("T")?.[0]) {
    if (time.split("T")[1].includes("Z")) {
      return time.split("T")[0] + " " + time.split("T")?.[1]?.split("Z")?.[0];
    }
    return time.split("T")[0] + " " + time.split("T")?.[1];
  }
  return time;
};

const inputReducer = (state, action) => {
  switch (action.type) {
    case "CHANGE":
      return {
        ...state,
        value: action.val,
        isValid: validate(action.val || "", action.validators),
      };
    case "TOUCH":
      return {
        ...state,
        isTouched: true,
      };
    case "MODEL_FILE":
      return {
        ...state,
      };
    default:
      return state;
  }
};

const InputNew = (props) => {
  const currentDate = dayjs().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");

  const [expanded, setExpanded] = useState(false);

  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const borderColor = isFocused ? "1px solid #cb3694" : "";

  let arrayCopy;
  if (props.traceList) {
    arrayCopy = JSON.parse(JSON.stringify(props.traceList)).reverse();
  }

  const [inputState, dispatch] = useReducer(inputReducer, {
    value: props.initialValue || "",
    isTouched: false,
    isValid: props.initialValid || false,
  });

  const { id, onInput } = props;
  const { value, isValid } = inputState;

  useEffect(() => {
    dispatch({
      type: "CHANGE",
      val: props.initialValue,
      validators: props.validators || [],
    });
    if (props.onBlur === "dashboard" || props.onBlur === "fileInput") {
      onInput && onInput(id, props.initialValue, isValid);
    }
  }, [props.initialValue]);

  useEffect(() => {
    if (props.onBlur !== "dashboard" && props.onBlur !== "fileInput") {
      onInput && onInput(id, value, isValid);
    }
  }, [id, value, isValid, onInput]);

  const changeHandler = (event, element) => {
    if (props.element === "autoFill") {
      dispatch({
        type: "CHANGE",
        val: event,
        validators: props.validators || [],
      });
    } else if (props.element === "textEditor") {
      dispatch({
        type: "CHANGE",
        val: event,
        validators: props.validators || [],
      });
    } else if (
      Array.isArray(event) &&
      Array.isArray(element) &&
      props.element !== "autoFill"
    ) {
      dispatch({
        type: "CHANGE",
        val: event,
        validators: props.validators || [],
      });
    } else if (props.fileLoad && event.target) {
      props.fileChangeHandler(
        event.target.id ? event.target.id : event.target.name,
        event.target.value
      );
    } else if (element && element.id) {
      dispatch({
        type: "CHANGE",
        val: event,
        validators: props.validators || [],
      });
    } else if (
      event.target.name === "issue_status" ||
      event.target.name === "model_status" ||
      event.target.name === "validation_status" ||
      event.target.name === "opm_status" ||
      event.target.name === "policy_status" ||
      event.target.name === "change_status" ||
      event.target.name === "inuse_status"
    ) {
      props.onRecord(event.target.value);
      dispatch({
        type: "CHANGE",
        val: event.target.value,
        validators: props.validators || [],
      });
    } else if (event.target.name.includes("file_status")) {
      dispatch({
        type: "CHANGE",
        val: event.target.value,
        validators: props.validators || [],
      });
    } else {
      dispatch({
        type: "CHANGE",
        val: event.target.value,
        validators: props.validators || [],
      });
    }
  };

  const blurHandler = (event) => {
    if (props.onBlur === "dashboard" || props.onBlur === "fileInput") {
      props.fileChangeHandler2 &&
        props.fileChangeHandler2(
          event.target.id ? event.target.id : event.target.name,
          event.target.value
        );

      onInput(id, value, isValid);
    }
  };

  const touchHandler = () => {
    dispatch({
      type: "TOUCH",
    });
  };

  const tableDataChangedHandler = (data) => {
    const assumptionData = data.map((item) => ({
      form: props.form,
      identifier: item.identifier,
      indicator: props.indicator,
      version: props.version,
      id: item.id,
      validation_id: props.validationId,
      sequence: props.sequence,
      assumption_type: props.label,
      condition: item.condition,
      assumption_status: item.assumption_status,
      monitoring_frequency: item.monitoring_frequency,
      user_id: props.userId,
      update_time: currentDate,
    }));
    dispatch({
      type: "CHANGE",
      val: assumptionData,
      validators: props.validators || [],
    });
  };

  const opmDataChangedHandler = (data) => {
    let opmData;
    if (props.id === "weight_schema") {
      opmData = data.map((item) => ({
        statistics: item.statistics,
        weight: item.weight !== undefined ? item.weight : null,
        id: item.id,
      }));
    } else if (props.id === "performance_measurement") {
      opmData = data.map((item) => ({
        color: item.color,
        lower_bound: item.lower_bound,
        upper_bound: item.upper_bound,
        statistics: item.statistics,
        opm_rule: item.opm_rule,
        quarterback: item.quarterback,
        series: item.series,
        continuity: item.continuity,
        statistics_type: "Quantitative",
        id: item.id,
      }));
    } else if (props.id === "attestation") {
      opmData = data.map((item) => ({
        id: item.id,
        attestation: item.attestation,
        color: item.color,
        lower_bound: null,
        upper_bound: null,
        statistics: item.statistics,
        opm_rule: item.opm_rule,
        quarterback: item.quarterback,
        series: item.series,
        continuity: item.continuity,
        statistics_type: "Qualitative",
      }));
    } else if (props.id === "score_schema") {
      opmData = data.map((item) => ({
        id: item.id,
        color: item.color,
        lower_bound: item.lower_bound,
        upper_bound: item.upper_bound,
        value: item.value,
        default_color: item.default_color,
      }));
    } else if (props.id === "quantitative_metric") {
      opmData = data.map((item) => ({
        id: item.id,
        statistics_type: "Quantitative",
        statistics: item.statistics,
        attestation1: "",
        attestation2: "",
        attestation3: "",
        attestation4: "",
        value1: item.value1,
        value2: item.value2,
        value3: item.value3,
        value4: item.value4,
      }));
    } else if (props.id === "qualitative_metric") {
      opmData = data.map((item) => ({
        id: item.id,
        statistics_type: "Qualitative",
        statistics: item.statistics,
        value: null,
        attestation1: item.attestation1,
        attestation2: item.attestation2,
        attestation3: item.attestation3,
        attestation4: item.attestation4,
        value1: null,
        value2: null,
        value3: null,
        value4: null,
      }));
    }

    dispatch({
      type: "CHANGE",
      val: opmData,
      validators: props.validators || [],
    });
  };

  const macDataChangedHandler = (data) => {
    let macData;
    if (props.id === "memo") {
      macData = data;
    } else if (props.id === "state") {
      macData = data;
    } else if (props.id === "attendance" || props.id === "mac_attendance") {
      macData = data;
    }
    dispatch({
      type: "CHANGE",
      val: macData,
      validators: props.validators || [],
    });
  };

  let element;
  switch (props.element) {
    case "input":
      element = (
        <TextField
          id={props.id}
          type={props.type}
          inputProps={{ min: 0, max: 999 }}
          placeholder={props.placeholder}
          onChange={changeHandler}
          onBlur={
            props.onBlur === "dashboard" || props.onBlur === "fileInput"
              ? blurHandler
              : props.onBlur
              ? touchHandler
              : null
          }
          value={
            inputState.value
              ? inputState.value
              : props.type === "number"
              ? 0
              : ""
          }
          disabled={props.disabled}
          variant="standard"
          fullWidth
          style={{ textAlign: "center" }}
          sx={{
            underline: {
              color: "white",
              borderBottom: "white",
              "&:before": {
                borderBottom: `1px solid rgba(224, 224, 224, 1)`,
              },
            },
          }}
        />
      );
      break;
    case "textarea":
      element = (
        <textarea
          id={props.id}
          rows={props.rows || 3}
          placeholder={props.placeholder}
          onChange={changeHandler}
          onBlur={touchHandler}
          value={inputState.value ? inputState.value : ""}
          disabled={props.disabled}
          spellCheck="true"
        />
      );
      break;
    case "date":
      element = (
        <TextField
          id={props.id}
          type="date"
          placeholder={props.placeholder}
          onChange={changeHandler}
          onBlur={
            props.onBlur
              ? props.onBlur === "dashboard"
                ? blurHandler
                : touchHandler
              : null
          }
          value={inputState.value ? inputState.value : ""}
          disabled={props.disabled}
          variant="standard"
          fullWidth
        />
      );
      break;
    case "select":
      element = (
        <select
          name={props.id}
          onChange={changeHandler}
          value={inputState.value ? inputState.value : ""}
          disabled={props.disabled}
          style={props.height}
        >
          {((Array.isArray(props.clean_options) &&
            !props.clean_options?.includes(inputState.value)) ||
            (Array.isArray(props.origin_options?.group?.split(",")) &&
              !props.origin_options?.group
                ?.split(",")
                ?.includes(inputState.value))) && (
            <option value={inputState.value} disabled>
              {inputState.value}
            </option>
          )}

          {props.clean_options &&
            props.clean_options.map((option, index) => (
              <option
                key={index}
                value={option}
                style={{ border: "1px solid red" }}
              >
                {option}
              </option>
            ))}
          {props.origin_options &&
            !props.clean_options &&
            props.origin_options.group &&
            props.origin_options.group.split(",").map((option, index) => (
              <option
                key={index}
                value={isNaN(option) ? option : option.toString()}
                style={{ border: "1px solid red" }}
              >
                {option}
              </option>
            ))}
          {(!inputState.value || props.initialValue === "") && (
            <option value="" style={{ display: "none" }}></option>
          )}
        </select>
      );
      break;
    case "autoFill":
      element = (
        <Select
          mode="tags"
          showArrow
          suffixIcon={
            <ExpandMoreIcon
              sx={{
                color: "#cacaca",

                width: "85%",

                ml: 4,
              }}
            />
          }
          showSearch={false}
          onChange={changeHandler}
          value={Array.isArray(inputState.value) ? inputState.value : []}
          disabled={props.disabled}
          style={{
            width: "100%",
          }}
          size="large"
          dropdownStyle={{
            zIndex: 999999999,
            backgroundColor: "white",
          }}
          options={
            Array.isArray(props.options) ? uniqBy(props.options, "label") : []
          }
        ></Select>
      );
      break;
    case "autoComplete":
      element = (
        <Select
          mode="multiple"
          showArrow
          suffixIcon={
            <ExpandMoreIcon
              sx={{
                color: "#cacaca",

                width: "85%",

                ml: 4,
              }}
            />
          }
          showSearch={false}
          onChange={changeHandler}
          value={
            inputState.value !== ""
              ? Array.isArray(inputState.value)
                ? inputState.value
                : inputState.value?.split(",")
              : []
          }
          disabled={props.disabled}
          style={{
            width: "100%",
          }}
          size="large"
          dropdownStyle={{
            zIndex: 999999999,
            backgroundColor: "white",
          }}
        >
          {props.clean_options &&
            Array.isArray(props.clean_options) &&
            uniq(props.clean_options).map((option, index) => (
              <Option
                key={index}
                value={option}
                name={props.id}
                className="multiple_select"
              >
                {option}
              </Option>
            ))}

          {!props.clean_options &&
            props.origin_options &&
            props.origin_options.group &&
            uniq(props.origin_options.group.split(",")).map((option, index) => (
              <Option
                key={index}
                value={isNaN(option) ? option : option.toString()}
              >
                {option}
              </Option>
            ))}

          {(!inputState.value || props.initialValue === "") && (
            <Option value="" style={{ display: "none" }}></Option>
          )}
        </Select>
      );
      break;
    case "radio":
      element = (
        <FormControl>
          <RadioGroup
            aria-labelledby="radio-buttons-group"
            name={props.id}
            value={inputState.value || ""}
            onChange={changeHandler}
          >
            {props.radioOptions.map((option, index) => (
              <FormControlLabel
                value={option.fe_label}
                control={<Radio />}
                label={option.fe_label}
                key={index}
              />
            ))}
          </RadioGroup>
        </FormControl>
      );
      break;
    case "textEditor":
      element = (
        <ReactQuill
          theme="snow"
          formats={formats}
          modules={modules}
          onChange={changeHandler}
          value={inputState.value || ""}
          id={props.id}
          readOnly={props.disabled}
          onFocus={handleFocus}
          onBlur={handleBlur}
          className={`h-[100rem] border-2 quill-wrapper ${
            props.disabled
              ? "disabled"
              : props.isToolBarDisabled
              ? "toolbar-disabled"
              : ""
          }`}
          style={{ border: borderColor }}
        />
      );
      break;
    case "metricTable":
      element = (
        <MetricTable
          tableData={inputState.value ? inputState.value : []}
          tableDataChanged={tableDataChangedHandler}
          label={props.label}
          disabled={props.disabled}
        />
      );
      break;
    case "opmTable":
      element = (
        <OpmMetric
          tableData={inputState.value ? inputState.value : []}
          tableDataChanged={opmDataChangedHandler}
          label={props.label}
          id={props.id}
          colorList={props.colorList}
          statisticsType={props.statisticsType}
          isAddButtonNotShow={props.isAddButtonNotShow}
          colorReference={props.colorReference}
          qualitativeOption={props.qualitativeOption}
          isShowOnly={props.isShowOnly}
          isFourColsShow={props.isFourColsShow}
          disabled={props.disabled}
        />
      );
      break;
    case "macTable":
      element = (
        <MacTable
          tableData={inputState.value ? inputState.value : []}
          tableDataChanged={macDataChangedHandler}
          label={props.label}
          id={props.id}
          modelIdGroup={props.modelIdGroup}
          userGroup={props.userGroup}
          mac_attendanceData={props.mac_attendance}
          disabled={props.disabled}
        />
      );
      break;
    case "time":
      element = (
        <input
          id={props.id}
          type="time"
          step="60"
          onChange={changeHandler}
          value={inputState.value ? inputState.value : ""}
          disabled={props.disabled}
          fullWidth
          placeholder="HH:mm:ss"
        />
      );
      break;
    default:
      break;
  }

  if (props.element === "metricTable") {
    return <div className="form-metricTable">{element}</div>;
  }

  if (props.element === "opmTable") {
    return <div>{element}</div>;
  }

  if (props.element === "macTable") {
    return <div>{element}</div>;
  }

  let element2;
  if (
    arrayCopy &&
    (props.id === "model_status" ||
      props.id.includes("file_status") ||
      props.id === "validation_status" ||
      props.id === "issue_status" ||
      props.id === "opm_status" ||
      props.id === "policy_status" ||
      props.id === "change_status" ||
      props.id === "inuse_status")
  ) {
    element2 = (
      <>
        <div style={{ display: "flex", alignItems: "center" }}>
          {element}
          {arrayCopy &&
            (props.id === "model_status" ||
              props.id.includes("file_status") ||
              props.id === "validation_status" ||
              props.id === "issue_status" ||
              props.id === "opm_status" ||
              props.id === "policy_status" ||
              props.id === "change_status" ||
              props.id === "inuse_status") && (
              <IconButton
                onClick={() => {
                  setExpanded((prev) => !prev);
                }}
                sx={{ p: 0, ml: 0.5 }}
              >
                {!expanded && (
                  <UpdateOutlinedIcon sx={{ color: "#CB3694", fontSize: 30 }} />
                )}
                {expanded && (
                  <UpdateDisabledOutlinedIcon
                    sx={{ color: "#FF9100", fontSize: 30 }}
                  />
                )}
              </IconButton>
            )}
        </div>
        <Accordion
          sx={{
            color: "inherit",
            borderRadius: "0 !important",
            borderTop: "none",
            borderRight: "none",
            borderLeft: "none",
            borderBottom: "none",
          }}
          onChange={() => {}}
          expanded={expanded}
        >
          <AccordionSummary
            sx={{
              p: 0,
              minHeight: 0,
              height: 0,
              "&.Mui-expanded": {
                minHeight: 0,
                height: 0,
              },
            }}
            aria-controls="status-access"
            id="status-access"
          ></AccordionSummary>

          <AccordionDetails sx={{ p: 0 }}>
            <Table hover>
              <tbody>
                {arrayCopy.map((log, key) => {
                  if (
                    props.origin_options.db_tablename !== "documents" &&
                    props.origin_options.db_tablename !== "issue" &&
                    props.origin_options.db_tablename !== "validation" &&
                    props.origin_options.db_tablename !== "policy" &&
                    props.origin_options.db_tablename !== "model change"
                  ) {
                    return (
                      <tr key={key}>
                        <td>{log[3]}</td>

                        <td>{log[4]}</td>

                        <td>{log[5] ? timeConverter(log[5]) : ""}</td>
                      </tr>
                    );
                  } else if (
                    props.origin_options.db_tablename === "issue" ||
                    props.origin_options.db_tablename === "validation"
                  ) {
                    return (
                      <tr key={key}>
                        <td>{log[4]}</td>

                        <td>{log[5]}</td>

                        <td>{log[6] ? timeConverter(log[6]) : ""}</td>
                      </tr>
                    );
                  } else if (
                    props.origin_options.db_tablename === "documents"
                  ) {
                    return (
                      <tr key={key}>
                        <td>{log[7]}</td>

                        <td>{log[9] ? timeConverter(log[9]) : ""}</td>
                      </tr>
                    );
                  } else if (props.origin_options.db_tablename === "policy") {
                    return (
                      <tr key={key}>
                        <td>{log[1]}</td>

                        <td>{log[2]}</td>

                        <td>{log[4] ? timeConverter(log[4]) : ""}</td>
                      </tr>
                    );
                  } else if (
                    props.origin_options.db_tablename === "model change"
                  ) {
                    return (
                      <tr key={key}>
                        <td>{log[2]}</td>

                        <td>{log[3]}</td>

                        <td>{log[4] ? timeConverter(log[4]) : ""}</td>
                      </tr>
                    );
                  }
                })}
              </tbody>
            </Table>
          </AccordionDetails>
        </Accordion>
      </>
    );
  } else {
    element2 = element;
  }

  return (
    <div
      className={`form-control ${
        !inputState.isValid && inputState.isTouched && "form-control--invalid"
      }`}
      style={
        props.isDocumentTable
          ? { fontSize: "12px" }
          : { backgroundColor: props.color }
      }
    >
      {!props.label?.includes("(*)") && props.label && (
        <label htmlFor={props.id}>{props.label}</label>
      )}
      {props.label?.includes("(*)") && props.label && (
        <label htmlFor={props.id}>
          {props.label.slice(0, -3)}(<span style={{ color: "#FF0000" }}>*</span>
          )
        </label>
      )}
      {element2}
      {!inputState.isValid && inputState.isTouched && <p>{props.errorText}</p>}
    </div>
  );
};

export default InputNew;
